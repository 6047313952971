// export const baseUrl = "http://localhost:30002";
// export const baseUrl = "http://13.209.88.168:30002";
export const baseUrl = "http://api.talktalktalk.info";

export const headers = {
  "Content-Type": "application/json;charset=UTF-8",
  Accept: "application/json",
  "Access-Control-Allow-Origin": "*",
  "X-Content-Type-Options": "nosniff",
  authorization: localStorage.getItem("accesstoken"),
};
