import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../store";
Vue.use(VueRouter);

// const requireAuth = () => (to, from, next) => {
//   let accesstoken = localStorage.getItem("accesstoken");
//   if (accesstoken == null || accesstoken == undefined) {
//     alert("로그인 후 사용가능 합니다.");
//     next("/");
//   } else {
//     next();
//   }
//   next();
// };

// NavigationDuplicated Error 해결 코드
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== "NavigationDuplicated") throw err;
  });
};

const requireAdmin = () => (to, from, next) => {
  let accesstoken = localStorage.getItem("accesstoken");

  if (accesstoken) {
    next();
  } else {
    next("/");
  }
};

// const redirect = () => (to, from, next) => {
//   let accesstoken = localStorage.getItem("accesstoken");
//   if (accesstoken) {
//     alert("이미 로그인 하였습니다.");
//     next("/");
//   } else {
//     next();
//   }
// };

const routes = [
  {
    path: "/",
    name: "Main",
    component: () => import("../views/Main.vue"),
  },

  {
    path: "/account",
    name: "account",
    component: () => import("../views/account/Container.vue"),

    children: [
      {
        path: "login",
        name: "login",
        component: () => import("../views/account/Login.vue"),
      },
    ],
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../views/legal/Privacy.vue"),
  },
  {
    path: "/terms",
    name: "terms",
    component: () => import("../views/legal/Terms.vue"),
  },
  {
    path: "/error404",
    name: "error404",
    component: () => import("../views/pop/Error404.vue"),
  },
  {
    path: "/error503",
    name: "error503",
    component: () => import("../views/pop/Error503.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/admin/Container.vue"),
    beforeEnter: requireAdmin(),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("../views/admin/Dashboard.vue"),
      },
      {
        path: "users",
        name: "users",
        component: () => import("../views/admin/user/Users.vue"),
      },
      {
        path: "user/detail",
        name: "userDetail",
        component: () => import("../views/admin/user/UserDetail.vue"),
      },
      {
        path: "word/manage",
        name: "wordManage",
        component: () => import("../views/admin/word/Manage.vue"),
      },
      {
        path: "word/register",
        name: "wordRegister",
        component: () => import("../views/admin/word/Register.vue"),
      },
      {
        path: "sentence/manage",
        name: "sentenceManage",
        component: () => import("../views/admin/sentence/Manage.vue"),
      },
      {
        path: "sentence/register",
        name: "sentenceRegister",
        component: () => import("../views/admin/sentence/Register.vue"),
      },
      {
        path: "notice/manage",
        name: "noticeManage",
        component: () => import("../views/admin/notice/Manage.vue"),
      },
      {
        path: "notice/register",
        name: "noticeRegister",
        component: () => import("../views/admin/notice/Register.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { x: 0, y: 0 };
      // return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
