import "babel-polyfill";
import "es6-promise/auto";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "@/styles/common.scss";
import "element-ui/lib/theme-chalk/index.css";
import ElementUI from "element-ui";
import { Collapse, Loading } from "element-ui";
import locale from "element-ui/lib/locale/lang/ko";
import Datepicker from "vuejs-datepicker";
import Tabs from "vue-material-tabs";
import VueI18n from "vue-i18n";
import messages from "./locales";
import Cookies from "js-cookie";

Vue.config.productionTip = true;
Vue.use(ElementUI, { locale });
Vue.use(Collapse);
Vue.use(Loading);
Vue.use(Datepicker);
Vue.use(Tabs);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: Cookies.get("language") || "en", // 쿠키에서 언어 정보를 가져오거나 기본값 설정
  messages,
});

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    if (this.$store.state.isLogin) {
      this.$store.dispatch("getAccountInfo");
      // this.$store.dispatch("getClubRequestCount");
      // this.$store.dispatch("getCertifyRequestCount");
    }
  },
  render: (h) => h(App),
}).$mount("#app");
