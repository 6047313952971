<template>
  <footer>
    <div class="top">
      <div class="flexB">
        <div class="left">
          <img class="logo" src="@/assets/images/logo_white.svg" alt="로고" />
          <div class="flex store">
            <!-- <router-link to="/">
              <img
                src="@/assets/images/appStore_white.png"
                alt="앱스토어 바로가기"
            /></router-link> -->
            <router-link to="/">
              <img
                src="@/assets/images/googlePlay_white.png"
                alt="구글플레이스토어 바로가기"
            /></router-link>
          </div>
        </div>
        <div class="right">
          <div class="link">
            <ul>
              <li><button @click="changeLanguage('ko')">한국어</button></li>
              <li><button @click="changeLanguage('en')">English</button></li>
              <!-- <li><button @click="changeLanguage('es')">Español</button></li>
              <li>
                <button @click="changeLanguage('cn')">中文 – 繁體</button>
              </li> -->
            </ul>
            <ul>
              <li>
                <router-link to="/terms">{{ $t("term") }}</router-link>
              </li>
              <li>
                <router-link to="/privacy">{{ $t("privacy") }}</router-link>
              </li>
              <li><router-link to="/faq">FAQ</router-link></li>
              <li><router-link to="/terms">iOS</router-link></li>
              <li><router-link to="/terms">Android</router-link></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="flexB">
        <div class="left">
          <!-- <p class="fwm">주식회사 바톤컴퍼니</p> -->
          <ul class="info">
            <li>대표 : 이원명</li>
            <li>연락처 : 02-866-8962</li>
            <li>사업자 등록번호 : 589-88-02120</li>
            <li>
              주소: 서울특별시 금천구 디지털로 9길 46, 이앤씨드림타워 7차 403호
            </li>
          </ul>
        </div>
        <div class="right">
          <p class="copyright">
            Copyright &copy; BatonCompany. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Cookies from "js-cookie";

export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      Cookies.set("language", lang, { expires: 365 });

      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>
